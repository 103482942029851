
import * as imgixRuntime$hQVueC8ZN9 from '/home/skorn/git.root/cf-web-static-nuxt-costeri/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21.0/node_modules/@nuxt/image/dist/runtime/providers/imgix'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "imgix",
  "domains": [
    "*.costeri.de",
    "localhost"
  ],
  "alias": {},
  "densities": [
    1,
    1,
    2
  ],
  "format": [
    "webp"
  ],
  "quality": 30
}

imageOptions.providers = {
  ['imgix']: { provider: imgixRuntime$hQVueC8ZN9, defaults: {"baseURL":"https://assets.costeri.de/"} }
}
        